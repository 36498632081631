import React, { useState } from 'react';
import MetricsTable from './components/MetricsTable';
import OrdersTable from './components/OrdersTable';
import './App.css';

const App = () => {
  const [activeTab, setActiveTab] = useState('metrics'); // State to track the active tab
  const [activeMetric, setActiveMetric] = useState('space'); // State to track the active metric

  return (
    <div>
      <nav>
        <button onClick={() => setActiveTab('metrics')}>Métricas</button>
        <button onClick={() => setActiveTab('orders')}>Pedidos</button>
      </nav>

      {activeTab === 'metrics' && (
        <div>
          <nav>
            <button
              className={activeMetric === 'space' ? 'active' : ''}
              onClick={() => setActiveMetric('space')}
            >
              Espacio Utilizado
            </button>
            <button
              className={activeMetric === 'stock' ? 'active' : ''}
              onClick={() => setActiveMetric('stock')}
            >
              Stock por SKU
            </button>
            <button
              className={activeMetric === 'orders' ? 'active' : ''}
              onClick={() => setActiveMetric('orders')}
            >
              Pedidos Recibidos
            </button>
            <button
              className={activeMetric === 'expiring' ? 'active' : ''}
              onClick={() => setActiveMetric('expiring')}
            >
              Vencimiento de Productos
            </button>
          </nav>
          <MetricsTable activeMetric={activeMetric} />
        </div>
      )}
      {activeTab === 'orders' && <OrdersTable />}
    </div>
  );
};

export default App;