import React, { useEffect, useState } from 'react';
import axios from 'axios';

const OrdersTable = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true); // Set loading to true before fetching
      try {
        const response = await axios.get('/api/coffeeshop/orders');
        setOrders(response.data);
      } catch (error) {
        console.error("Error fetching orders!", error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchOrders();
  }, []);

  if (loading) {
    return <div className="spinner"></div>; // Spinner for loading orders
  }

  const handleViewDetails = (order) => {
    setSelectedOrder(order);
  };

return (
    <div>
      <h2>Tabla de Pedidos</h2>
      <table>
        <thead>
          <tr>
            <th>Fecha y Hora de Recepción</th>
            <th>ID de Pedido</th>
            <th>Estado del Pedido</th>
            <th>SKU</th>
            <th>Cantidad</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {orders.map(order => (
            <tr key={order.id}>
              <td>{order.delivery_date}</td>
              <td>{order.id}</td>
              <td>{order.status}</td>
              <td>{order.product_sku}</td>
              <td>{order.quantity}</td>
              <td>
                <button onClick={() => handleViewDetails(order)}>Ver Más</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedOrder && (
        <div className="detail">
          <h3>Detalle del Pedido</h3>
          <p><strong>ID:</strong> {selectedOrder.id}</p>
          <p><strong>Fecha y Hora:</strong> {selectedOrder.delivery_date}</p>
          <p><strong>Estado:</strong> {selectedOrder.status}</p>
          <h4>Items:</h4>
          <ul>
            {selectedOrder.items.map((item, index) => (
              <li key={index}>{`SKU: ${item.sku}, Cantidad: ${item.quantity}`}</li>
            ))}
          </ul>
          <button onClick={() => setSelectedOrder(null)}>Cerrar</button>
        </div>
      )}
    </div>
  );
};

export default OrdersTable;
