import React, { useEffect, useState } from 'react';
import axios from 'axios';

const MetricsTable = ({ activeMetric }) => {
  const [space, setSpace] = useState([]);
  const [stockSKU, setStockSKU] = useState({});
  const [ordersReceived, setOrdersReceived] = useState([]);
  const [expiringProducts, setExpiringProducts] = useState([]);

  // Individual loading states for each table
  const [loadingSpace, setLoadingSpace] = useState(true);
  const [loadingStock, setLoadingStock] = useState(true);
  const [loadingOrders, setLoadingOrders] = useState(true);
  const [loadingExpiring, setLoadingExpiring] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingSpace(true);
        const spaceResponse = await axios.get('/api/coffeeshop/metricas/espacio_utilizado');
        setSpace(spaceResponse.data);
      } catch (error) {
        console.error("Error fetching space data!", error);
      } finally {
        setLoadingSpace(false);
      }

      try {
        setLoadingStock(true);
        const stockResponse = await axios.get('/api/coffeeshop/metricas/stock_sku');
        setStockSKU(stockResponse.data);
      } catch (error) {
        console.error("Error fetching stock data!", error);
      } finally {
        setLoadingStock(false);
      }

      try {
        setLoadingOrders(true);
        const ordersResponse = await axios.get('/api/coffeeshop/metricas/pedidos_recibidos');
        setOrdersReceived(ordersResponse.data);
      } catch (error) {
        console.error("Error fetching orders data!", error);
      } finally {
        setLoadingOrders(false);
      }

      try {
        setLoadingExpiring(true);
        const expiringResponse = await axios.get('/api/coffeeshop/metricas/vencimiento_productos');
        setExpiringProducts(expiringResponse.data);
      } catch (error) {
        console.error("Error fetching expiring products data!", error);
      } finally {
        setLoadingExpiring(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <h2>Métricas</h2>

      {/* Render Space Used Table */}
      {activeMetric === 'space' && (
        <div>
        <h3>Espacio Utilizado en la Cafetería</h3>
        {loadingSpace ? (
          <div className="spinner"></div> // Spinner for space loading
        ) : (
          <table>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Cantidad</th>
              </tr>
            </thead>
            <tbody>
              {space.map((entry) => (
                <tr key={entry.Nombre}>
                  <td>{entry.Nombre}</td>
                  <td>{entry.UsedSpace}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    )}

      {/* Render Stock by SKU Table */}
      {activeMetric === 'stock' && (
        <div>
          <h3>Stock por SKU</h3>
          {loadingStock ? (
            <div className="spinner"></div> // Spinner for stock loading
          ) : (
            <table>
              <thead>
                <tr>
                  <th>SKU</th>
                  <th>Cantidad</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(stockSKU).map(([sku, quantity]) => (
                  <tr key={sku}>
                    <td>{sku}</td>
                    <td>{quantity}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}

      {/* Render Orders Received Table */}
      {activeMetric === 'orders' && (
        <div>
          <h3>Pedidos Recibidos por Hora</h3>
          {loadingOrders ? (
            <div className="spinner"></div> // Spinner for orders loading
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Hora</th>
                  <th>Cantidad</th>
                </tr>
              </thead>
              <tbody>
                {ordersReceived.map((order) => (
                  <tr key={order.hour}>
                    <td>{order.hour}</td>
                    <td>{order.count}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}

      {/* Render Expiring Products Table */}
      {activeMetric === 'expiring' && (
        <div>
          <h3>Vencimiento de Productos en las Próximas 3 Horas</h3>
          {loadingExpiring ? (
            <div className="spinner"></div> // Spinner for expiring products loading
          ) : (
            <table>
              <thead>
                <tr>
                  <th>SKU</th>
                  <th>Fecha de Vencimiento</th>
                </tr>
              </thead>
              <tbody>
                {expiringProducts.map((product) => (
                  <tr key={product.sku}>
                    <td>{product.sku}</td>
                    <td>{product.expirationDate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};

export default MetricsTable;
